<div *ngIf="beforeEmailSent; then beforeSent; else afterSent"></div>

<ng-template #beforeSent>
  <div *ngIf="!isMobile" class="main-menu-container">
    <div class="header">
      <img class="shiba-logo" src="assets/main-menu/beyond.png" />
      <img class="logo" src="assets/main-menu/svg-cards/gertner_logo1.png" />
    </div>
    <div class="login-div-border-wrap">
      <div class="login-div">
        <h2 class="login-header">Forgot Password</h2>
        <p
          class="login-tagline"
          [ngClass]="{
            'login-tagline-error': submitted && f.username.errors && f.username.errors.errorName === 'not_valid'
          }"
        >
          Please enter your username to reset your password
        </p>
        <div class="form-group" [style.top.px]="'30'">
          <div class="field-header">Username</div>
          <input type="text" class="form-control" [(ngModel)]="username" placeholder="Username" />
        </div>
        <div class="error-message-wrapper">
          <div class="message-place" [ngClass]="{ on: onError }">
            <img class="error-image" src="../../../../assets/login/inputs_error.svg" />
            <div class="message">
              {{ message }}
            </div>
          </div>
        </div>

        <div class="form-group">
          <button
            [disabled]="!username"
            class="btn btn-primary"
            [ngClass]="{ on: username !== '' }"
            (click)="onSubmit()"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isMobile" class="main-menu-container-mobile">
    <div class="header-mobile">
      <img class="shiba-logo" src="assets/main-menu/beyond.png" />
      <img class="logo-mobile" src="assets/main-menu/svg-cards/gertner_logo1.png" />
    </div>
    <div class="login-div-border-wrap-mobile">
      <div class="login-div-mobile">
        <h2 class="login-header-mobile">Forgot Password</h2>
        <p
          class="login-tagline-mobile"
          [ngClass]="{
            'login-tagline-error-mobile': submitted && f.username.errors && f.username.errors.errorName === 'not_valid'
          }"
        >
          Please enter your username to reset your password
        </p>
        <div class="form-group-mobile" [style.top.px]="'30'">
          <div class="field-header-mobile">Username</div>
          <input type="text" class="form-control-mobile" [(ngModel)]="username" />
        </div>
        <div class="error-message-wrapper-mobile">
          <div class="message-place-mobile" [ngClass]="{ on: onError }">
            <img class="error-image-mobile" src="../../../../assets/login/inputs_error.svg" />
            <div class="message-mobile">
              {{ message }}
            </div>
          </div>
        </div>

        <div class="form-group-mobile">
          <button
            [disabled]="!username"
            class="btn-mobile btn-primary-mobile"
            [ngClass]="{ on: username !== '' }"
            (click)="onSubmit()"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #afterSent>
  <div *ngIf="!isMobile" class="main-menu-container">
    <div class="header">
      <img class="shiba-logo" src="assets/main-menu/beyond.png" />
      <img class="logo" src="assets/main-menu/svg-cards/gertner_logo1.png" />
    </div>
    <div class="login-div-border-wrap">
      <div class="login-div">
        <h2 class="login-header">Thank You!</h2>
        <div class="reset-success">
          We have just sent you an email with further instructions.
          <br />
          If you haven’t received the email, please contact your therapist.
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isMobile" class="main-menu-container">
    <div class="header-mobile">
      <img class="shiba-logo" src="assets/main-menu/beyond.png" />
      <img class="logo-mobile" src="assets/main-menu/svg-cards/gertner_logo1.png" />
    </div>
    <div class="login-div-border-wrap-mobile">
      <div class="login-div-mobile">
        <h2 class="login-header-mobile">Thank You!</h2>
        <div class="reset-success-mobile">
          We have just sent you an email with further instructions.
          <br />
          If you haven’t received the email, please contact your therapist.
        </div>
      </div>
    </div>
  </div>
</ng-template>
