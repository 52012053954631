<div class="end-game-modal">
    <div class="end-game-dark-overlay"></div>
    <div class="end-game-message-wrapper">
        <div class="top-section">
            <h2 class="end-game-title">Activity done</h2>
            <p class="end-game-subtitle">Returning to the activities board in 5 sec</p>    
        </div>
        <div class="back-home-countdown">
            <div class="skeleton-loading-bar">
                <div class="loading-fill"></div>
                <div class="loading-fill loading-fill-erase"></div>
            </div>
            <div class="time-left-wrapper">
                <p class="time-left-title">Time left</p>
                <p id="secondsLeft" class="seconds-left-number">0{{ secondsLeft }}<span class="seconds-left-span">sec</span></p>
            </div>
        </div>
    </div>
</div>