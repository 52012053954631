<div class="container">
  <app-calendar-header
    class="header"
    [loggedInUsername]="getTherapistNameAndBookingNumber()"
    [dateToDisplay]="headerDateString"
    [allowWeekChange]="!isDuringLocalChanges"
    [isUniqueWeek]="isUniqueWeek"
    [showBack]="true"
    [showViewOptions]="true"
    (showListView)="setShowListView($event)"
    (weekChangeEvent)="weekChange($event)"
    (goBack)="backClicked()"
  ></app-calendar-header>

  <div class="main">
    <app-availability-management
      *ngIf="!showListView"
      class="management"
      [availability]="therapistAvailability.availability"
      [isUniqueWeek]="isUniqueWeek"
      (resetTherapistAvailability)="resetTherapistAvailability()"
      (setTherapistAvailability)="setTherapistAvailability($event)"
      (deleteTherapistAvailability)="deleteTherapistAvailability()"
      (setIsDuringLocalChanges)="setIsDuringLocalChanges($event)"
    ></app-availability-management>
    <app-calendar
      *ngIf="!showListView"
      class="calendar"
      [dayOfWeekToShow]="currentDate"
      [therapistBookingDetails]="bookingData"
      [therapistAvailability]="therapistAvailability"
      (updatePatient)="updatePatient($event)"
      (deleteBooking)="onDeleteBooking($event)"
    ></app-calendar>
    <app-treatment-list
      *ngIf="showListView"
      class="treatments"
      [therapistBookingViewData]="therapistBookingViewData"
      [currentDate]="currentDate"
      [availabilityClickActionType]="availabilityClickActionType"
      [bookingTimeClickActionType]="bookingClickActionType.ModifySession"
      [bookingClickActionType]="bookingClickActionType.ShowCoPatients"
      [isFormerDates]="isFormerDates"
      (actionSubmit)="showTreatmentDetailsModal($event)"
      (deleteOrReplaceBooking)="handleDeleteOrReplaceBooking($event)"
    ></app-treatment-list>
    <app-calendar-item-modal
      *ngIf="showModal"
      [coPatients]="coPatients"
      [coPatientPersonalMenuOptions]="coPatientPersonalMenuOptions"
      [headerTitle]="getModalHeaderTitle()"
      (onSelectedCoPatient)="onSelectedCoPatient($event)"
      (onClose)="onItemModalClose()"
      (onReasonSave)="updatePatient($event)"
    ></app-calendar-item-modal>
  </div>
</div>
