<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div class="container">
  <mat-dialog-actions *ngIf="isGameConfiguratorInTherapistColorset; else oldStyle" class="dialog-actions-therapist-colorset" align="end">
    <button class="leave-button-therapist-colorset" mat-button mat-dialog-close></button>
  </mat-dialog-actions>
  <ng-template #oldStyle>
    <mat-dialog-actions class="dialog-actions" align="end">
      <button class="leave-button" mat-button mat-dialog-close></button>
    </mat-dialog-actions>
  </ng-template>
  <mat-dialog-content>
    <iframe class="iframe-contianer" frameBorder="0" [src]="game.url | urlSanitaizer" (load)="onIframeLoad()" allow="autoplay *"
      id="game-iframe" allowfullscreen [ngStyle]="{transform: 'scale(0.7)',
      transformOrigin: 'left top',
      width: 'calc(100% * (1 / 0.7))',
      height: 'calc(100% * (1 / 0.7))'
      }">
    </iframe>
  </mat-dialog-content>
</div>