<div class="game-message-wrapper">
  <div class="game-message-component">
    <div class="game-message-container">
      <div class="img">
        <img src="../../../../assets/main-menu/reading_mark.svg" />
      </div>
      <div class="content">
        <p class="instruction">Instruction</p>
        <p class="message">{{ gameMessage }}</p>
      </div>
    </div>
  </div>
</div>
