<div class="game-percentage-wrapper">
  <div class="percentage">
    <svg viewBox="0 0 36 36" class="game-percentage-loader">
      <path
        class="game-percentage-loader-bg"
        d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        id="game-percentage-circle"
        class="game-percentage-circle"
        [ngStyle]="{'strokeDashoffset': 100 - this.percentage}"
        stroke-dasharray="100, 100"
        d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
      />
    </svg>
    <p id="percentage-text" class="percentage-text">{{ percentage }}%</p>
  </div>
  <div class="percentage-title-wrapper">
    <p class="percentage-title">Progress</p>
  </div>
</div>
