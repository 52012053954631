<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
<meta http-equiv="Pragma" content="no-cache" />
<meta http-equiv="Expires" content="0" />
<meta http-equiv="Cross-Origin-Resource-Policy" content="same-origin" />
<meta http-equiv="Cross-Origin-Embedder-Policy" content="require-corp" />
<meta http-equiv="Cross-Origin-Opener-Policy" content="same-origin" />
<div id="main-app">
  <router-outlet></router-outlet>
</div>
<!-- <audio id="call-audio">
    <source src="assets/audio/skype_call.mp3" type="audio/mpeg">
</audio> -->
