<div class="header-container">
  <div class="back" (click)="onClickBack()">
    <img src="/assets/backoffice/icon_back_office_back.svg" />
    {{ getProfessionName() }} Expertise Management
  </div>
</div>

<div class="content">
  <div class="table-container">
    <app-backoffice-table [columns]="columns" [rows]="formattedExpertiseArray" [additionalActions]="actions">
    </app-backoffice-table>
    <hr *ngIf="expertiseArray.length > 0" />
  </div>

  <form [formGroup]="customForm">
    <div class="expertise">
      <label class="expertise-name-container">
        <input type="text" formControlName="name" required />
      </label>
      <label class="field-container">
        <app-select formControlName="max_patients" required [items]="maxPatientsOptions"> </app-select>
      </label>
      <label class="field-container">
        <app-select formControlName="duration" required [items]="durationOptions"> </app-select>
      </label>
      <label class="field-container">
        <div class="add" [class.disabled]="!isValid" (click)="saveExpertise()">
          <div class="add-text">{{ editExpertiseMode ? 'SAVE' : 'ADD' }}</div>
        </div>
      </label>
    </div>
  </form>
</div>

<div class="backdrop-container" *ngIf="showRelatedExpertiseItemsExists">
  <div class="modal-container">
    <div class="header">Delete Expertise</div>
    <div *ngIf="currentExpertisePatientTreatmentAmount > 0" class="details">
      There are {{ currentExpertisePatientTreatmentAmount }} patient treatments for this expertise.
      <br />
      Please remove them before deleting the expertise.
      <br />
    </div>
    <div *ngIf="currentExpertiseTherapistAmount > 0" class="details">
      There are {{ currentExpertiseTherapistAmount }} therapists assigned to the expertise.
      <br />
      Please unassign them before deleting the expertise.
      <br />
    </div>

    <div class="buttons-container">
      <div class="button" (click)="hideRelatedExpertiseItemsExists()">Cancel</div>
    </div>
  </div>
</div>

<div class="backdrop-container" *ngIf="showOneExpertiseMessage">
  <div class="modal-container">
    <div class="header">Delete Expertise</div>
    <div class="details">
      You can't delete the expertise.
      <br />
      Profession must have at least one expertise.
    </div>
    <div class="buttons-container">
      <div class="button" (click)="hideOneExpertiseMessage()">OK</div>
    </div>
  </div>
</div>
