<div class="main-menu-container">
  <div class="header">
    <img class="shiba-logo" src="assets/main-menu/beyond.png" />
    <img class="logo" src="assets/main-menu/svg-cards/gertner_logo1.png" />
  </div>
  <div class="login-div-border-wrap">
    <div class="login-div">
      <ng-container [ngSwitch]="pageStatus">
        <ng-container *ngSwitchCase="enumConst.CHECK_EXPIRED_TOKEN"> </ng-container>
        <ng-container *ngSwitchCase="enumConst.WAITING_USERS_INPUT">
          <div class="login-header">RESET PASSWORD</div>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <div class="field-header">New password</div>
              <div class="password-container">
                <input
                  [type]="showNewPassword ? 'text' : 'password'"
                  formControlName="password"
                  class="form-control"
                  placeholder="New password"
                />
                <div class="eye-icon-wrapper" (click)="showNewPassword = !showNewPassword">
                  <img
                    [src]="
                      showNewPassword
                        ? '../../../../assets/login/show_password_white.svg'
                        : '../../../../assets/login/hide_password_white.svg'
                    "
                    class="eye-icon"
                  />
                </div>
              </div>
            </div>
            <div class="form-group password-field">
              <div class="field-header">Confirm password</div>
              <div class="password-container">
                <input
                  [type]="showConfirmPassword ? 'text' : 'password'"
                  formControlName="rePassword"
                  class="form-control"
                  placeholder="Confirm password"
                />
                <div class="eye-icon-wrapper" (click)="showConfirmPassword = !showConfirmPassword">
                  <img
                    [src]="
                      showConfirmPassword
                        ? '../../../../assets/login/show_password_white.svg'
                        : '../../../../assets/login/hide_password_white.svg'
                    "
                    class="eye-icon"
                  />
                </div>
              </div>
            </div>
            <div class="error-message-wrapper">
              <div class="message-place" [ngClass]="{ on: errorMessage }">
                <img class="error-image" src="../../../../assets/login/inputs_error.svg" />
                <div *ngIf="errorMessage === 'PASSWORD_NOT_STRONG'" class="message on">
                  Your password must contain:
                  <li>minimum 8 characters</li>
                  <li>uppercase and lowercase letters</li>
                  <li>a number</li>
                </div>
                <div *ngIf="errorMessage === 'PASSWORD_NOT_MATCH'" class="message on not-match">
                  Your passwords did not match. <br />
                  Please try again.
                </div>
              </div>
            </div>

            <div class="form-group">
              <button class="btn btn-primary on">Submit</button>
            </div>
          </form>
        </ng-container>
        <ng-container *ngSwitchCase="enumConst.PASSWORD_CHANGED">
          <div class="login-header">Success!</div>
          <div class="login-ref">An email with your login<br />Data was sent to your mail</div>
        </ng-container>
        <ng-container *ngSwitchCase="enumConst.ERROR_TOKEN_NOT_VALID">
          <div class="login-header" [style.color]="'#ed605c'">Failed!</div>
          <div class="not-valid-token">
            This link expired or not valid.<br />
            Please call your therapist for further instructions.
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
