<div class="game-timer-wrapper">
    <div class="timer">
        <div class="square">
            <p id="minutes" class="square-text">{{ minutes }}</p>
        </div>
        <p class="timer-colon">&#x3a;</p>
        <div class="square">
            <p id="seconds" class="square-text">{{ seconds }}</p>
        </div>
    </div>
    <div class="timer-title-wrapper">
        <p class="timer-title">Time</p>
    </div>
</div>